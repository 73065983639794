const colors = {
    orange: "#ffd000 ",
    darkerorange: "#C3A77D",
    secondary: "#2ecc71",
    background: "#ecf0f1",
    text: "#2c3e50",
    white: "#ffffff",
    black: "#000000",
    zap: '#25D366',
    cinzaclaro: '#f8f7f7',
};

export default colors;